<!-- start page title -->
<app-pagetitle title="Bookings" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row mt-3">
  <div class="col-lg-6">
      <div class="card">
          <div class="card-body">
              <div id="circle_radialbar" class="apex-charts" dir="ltr">
                  <apx-chart [series]="customAngleChartOptions.series" [chart]="customAngleChartOptions.chart"
                      [plotOptions]="customAngleChartOptions.plotOptions" [labels]="customAngleChartOptions.labels"
                      [legend]="customAngleChartOptions.legend" [colors]="customAngleChartOptions.colors"
                      [responsive]="customAngleChartOptions.responsive"></apx-chart>
              </div>
          </div>
      </div>
      <!--end card-->
  </div><!-- end col -->
  <div class="col-lg-6">
      <div class="card">
          <div class="card-body">
            <div id="monochrome_pie_chart" class="apex-charts" dir="ltr">
              <apx-chart [series]="semiDonutChartOptions.series" [chart]="semiDonutChartOptions.chart"
                  [labels]="semiDonutChartOptions.labels"
                  [dataLabels]="semiDonutChartOptions.dataLabels" [legend]="semiDonutChartOptions.legend">
              </apx-chart>
            </div>
          </div>
      </div>
      <!--end card-->
  </div><!-- end col -->
</div><!-- end row -->

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body data-table-card">
        <div class="table-responsive mt-4 mt-sm-0 datatables">
          <table class="table align-middle table-nowrap table-check">
            <thead>
              <div class="search-container">
                <span class="form-booking-size form-booking-search">Search: </span>
                <input #inp type="text" id="search" class="form-control form-control-sm ms-2 form-input-bg form-booking-size" [(ngModel)]="searchTerm" (ngModelChange)="search()"  placeholder="Find by name">
              </div>
            <tr class="bg-transparent">
              <th style="width: 30px;">
                <div class="form-check font-size-16">
                  <input type="checkbox" [(ngModel)]="masterSelected" class="form-check-input" id="checkAll" (change)="checkUncheckAll()">
                  <label class="form-check-label" for="checkAll"></label>
                </div>
              </th>
              <th>Booking ID</th>
              <th class="sortable" (click)="sort({ column: 'created_at', direction: isDesc ? 'asc' : 'desc' })">Reservation</th>
              <th class="sortable" (click)="sort({ column: 'checkin_date', direction: isDesc ? 'asc' : 'desc' })">Arrival</th>
              <th>Nights</th>
              <th>Guest name</th>
              <th>Guests</th>
              <th>Status</th>
              <th style="width: 120px;">Action</th>
            </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="bookings.length > 0; else noBookingsFound">
            <tr *ngFor="let booking of bookings; let index;">
              <td>
                <div class="form-check font-size-16">
                  <input type="checkbox" class="form-check-input" [(ngModel)]="booking.isSelected" id="contacusercheck1">
                  <label class="form-check-label" for="contacusercheck1"></label>
                </div>
              </td>
              <td>{{ booking.id}}</td>
              <td>{{ booking.created_at | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
              <td>{{ booking.checkin_date | date: 'yyyy-MM-dd' }}</td>
              <td>{{ booking.nrOfNights }}</td>
              <td>{{ booking.first_name }} {{ booking.last_name }}</td>
              <td>{{ booking.nrOfGuests }}</td>
              <td style="text-transform: capitalize;">
                <i class="uil-check-circle" *ngIf="booking.status === 'cancelled'" style="color: red;"></i>
                <i class="uil-check-circle" *ngIf="booking.status === 'confirmed'" style="color: green;"></i>
                <i class="uil-check-circle" *ngIf="booking.status === 'pending'" style="color: rgb(228, 156, 12);"></i>
                {{ booking.status }}
              </td>
              <td>
                <div class="dropdown" ngbDropdown placement="bottom-right">
                  <button class="btn btn-light btn-sm font-size-16 shadow-none py-0 text-muted dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                    <i class="bx bx-dots-horizontal-rounded"></i>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <li class="px-4">
                      <a type="button" class="btn btn-outline-info" data-bs-target=".bs-example-modal-lg"  data-bs-target="#myModal"
                      (click)="openbookingDetailsModal(showBookingDetailsModal,index)"><i class="uil uil-pen me-2"></i>Manage</a>
                    </li>
                    <li><a class="dropdown-item" type="button" (click)="print()">Print</a></li>
                    <li><a class="dropdown-item" href="javascript:void(0);">Delete</a></li>
                  </ul>
                </div>
              </td>
            </tr>
          </ng-container>
            <ng-template #noBookingsFound>
              <tr>
                <td colspan="8" class="text-center no-bookings">No bookings found</td>
              </tr>
            </ng-template>
            </tbody>
          </table>
        </div>

        <!-- End table -->
        <div class="row justify-content-md-between align-items-md-center mt-2">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
              Showing
              {{(bookings.length > 0 ? 1 : 0)}} to
              {{bookings.length}}
              of {{totalRecords}}
              entries
            </div>
          </div>

          <!-- Pagination -->
          <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-end pagination-rounded">
              <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" (pageChange)="onPageChange($event)">
              </ngb-pagination>
            </div>
          </div>
          <!-- End Pagination -->
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->
<ng-template #showBookingDetailsModal role="document" let-modal>
  <div class="modal-header">
    <h5 *ngIf="bookingDetails.status == 'cancelled'" class="modal-title" id="myModalLabel">Booking cancelled</h5>
    <h5 *ngIf="bookingDetails.status == 'pending'" class="modal-title" id="myModalLabel">Booking request pending</h5>
    <h5 *ngIf="bookingDetails.status == 'confirmed'" class="modal-title" id="myModalLabel">Booking confirmed</h5>
    <h5 *ngIf="bookingDetails.status == 'failed'" class="modal-title" id="myModalLabel">Booking failed</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="modal.close('Close click')">
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
          <dt class="col-sm-4">Guest</dt>
          <dd class="col-sm-8">{{bookingDetails.salutation}} {{bookingDetails.first_name }} {{bookingDetails.last_name}}</dd>
          <dt class="col-sm-4"></dt>
          <dd class="col-sm-8">{{bookingDetails.phone_number}}</dd>
          <dt class="col-sm-4"></dt>
          <dd class="col-sm-8">{{bookingDetails.email}}</dd>
          <dt class="col-sm-4">Address</dt>
          <dd class="col-sm-8">{{bookingDetails.street}} {{bookingDetails.number}}</dd>
          <dt class="col-sm-4"></dt>
          <dd class="col-sm-8">{{bookingDetails.postcode}} {{bookingDetails.city}}</dd>
          <dt class="col-sm-4">HotelCard</dt>
          <dd class="col-sm-8">{{bookingDetails.card_number}}</dd>
    </div>
    <hr>
    <div class="row">
      <dt class="col-sm-4">Room</dt>
      <dd class="col-sm-8">{{bookingDetails.room_name}}</dd>
      <dt class="col-sm-4"></dt>
      <dd class="col-sm-8">{{bookingDetails.adults}} Adults,{{bookingDetails.children}} Children,{{bookingDetails.infants}} Infants</dd>
      <dt class="col-sm-4">Check-in</dt>
      <dd class="col-sm-8 d-flex">{{bookingDetails.checkin_date}}</dd>
      <dt class="col-sm-4">Check-out</dt>
      <dd class="col-sm-8 d-flex">{{bookingDetails.checkout_date}}</dd>
    </div>
    <hr>
    <div class="row">
      <dt class="col-sm-8">Price</dt>
      <dd class="col-sm-4">
        <tr *ngFor="let item of roomNightPrice | keyvalue">
          <div class="row">
            <td class="col-sm-4 text-nowrap">
              <dd class="d-flex justify-content-end">{{item.value}} night </dd>
            </td>
            <td class="col-sm-8 text-nowrap">
              <dd class="d-flex justify-content-end">{{item.key}} {{bookingDetails.currency}}</dd>
            </td>
          </div>
        </tr>
      </dd>
      <dd class="col-sm-3">Breakfast</dd>
      <dd class="col-sm-9 d-flex justify-content-end">{{bookingDetails.wants_breakfast === 1 ? 'Included' : 'Not Included'}}</dd>
        <tr *ngFor="let artical of articlePrice; let index;">
          <div class="row">
            <td class="col-sm-9 text-nowrap">{{artical.label}}</td>
            <td class="col-sm-3 text-nowrap d-flex justify-content-end">
              <dd class="d-flex justify-content-end">{{artical.price}}</dd>
            </td>
          </div>
        </tr>
    </div>
    <hr>
    <div class="row">
      <dt class="col-sm-3 fw-bold">Total</dt>
      <dd class="col-sm-9 d-flex justify-content-end fw-bold">{{bookingDetails.price}} {{bookingDetails.currency}}</dd>
    </div>
    <div class="row">
      <div *ngIf="bookingDetails.cancelled_at !== null;">
        <hr>
        <dt class="col-sm-6">Guest Comments</dt>
        <div *ngIf="bookingDetails.cancelled_at !== null; else elseBlock">
          <p class="mat-subheading-2 mt-20 mb-20">This booking has been cancelled on {{bookingDetails.cancelled_at}} by {{bookingDetails.first_name}} {{bookingDetails.last_name}}</p>
        </div>
      </div>
      <ng-template #elseBlock>
          <p *ngIf="bookingDetails.cancelled_at !== null" class="mat-subheading-2 mt-20 mb-20">
            This booking has been cancelled on {{bookingDetails.cancelled_at}} by <strong>{{bookingDetails.first_name}} {{bookingDetails.last_name}}</strong>
          </p>
          <p *ngIf="!bookingDetails.hotel.auto_booking && !bookingDetails.hotel.channel_manager_id" class="mat-subheading-2 mt-20 mb-20">
            The Hotelcard availability was adjusted accordingly by <strong>{{bookingDetails.first_name}} {{bookingDetails.last_name}}</strong>'s booking request.
          </p>
          <p *ngIf="bookingDetails.hotel.auto_booking || bookingDetails.hotel.channel_manager_id" class="mat-subheading-2 mt-20 mb-20">
            The booking has been generated by <strong>{{bookingDetails.first_name}} {{bookingDetails.last_name}}</strong> and matched with the availability provided to Hotelcard.
          </p>
          <p *ngIf="bookingDetails.hotel.auto_booking === null && bookingDetails.hotel.channel_manager_id === null" class="mat-subheading-2 mt-20 mb-20">
            Please confirm the booking made by <strong>{{bookingDetails.first_name}} {{bookingDetails.last_name}}</strong>
            <a href='mailto:{{bookingDetails.email}}?subject=Booking%20confirmation%20from%20{{bookingDetails.room_name}}'>
            {{bookingDetails.email}}</a> within 24 hours. Thank you.
          </p>
          <p *ngIf="bookingDetails.hotel.auto_booking === 1" class="mat-subheading-2 mt-20 mb-20">
            This booking has already been automatically confirmed to the customer as you have the <strong>Auto</strong> booking mode activated.
          </p>
          <p *ngIf="bookingDetails.hotel.channel_manager_id" class="mat-subheading-2 mt-20 mb-20">
            This booking has been confirmed automatically because your property is connected to Hotelcard via a channel management system.
          </p>
          <p *ngIf="bookingDetails.hotel.auto_booking === 0 && !bookingDetails.confirm_key" [class.success]="confirm_success || bookingDetails.confirm_success"
            class="mat-subheading-2 mt-20 mb-20">You have confirmed the booking successfully.
          </p>
          <p *ngIf="bookingDetails.hotel.auto_booking === 0 && bookingDetails.confirm_key" class="mat-subheading-2 mt-20 mb-20">
            Please confirm this booking by clicking on confirm button
            <button  type="button" class="btn btn-success btn-rounded" *ngIf="!confirm_success"
              [class.spinner]="loading" [disabled]="loading" (click)="confirmBooking({hotel_id: bookingDetails.hotel_id, booking_id: bookingDetails.id, key: bookingDetails.confirm_key})">
              Confirm booking
            </button>
            <span *ngIf="confirm_success" class="text-success">You have confirmed the booking successfully.</span>
          </p>
      </ng-template>
    </div>
    <div class="row">
      <div *ngIf="bookingDetails.cancelDetails.cancelAlertLabel != ''">
        <hr>
        <dt *ngIf="bookingDetails.cancelDetails.cancelAlertLabel != ''" class="col-sm-6">Cancelation Details</dt>
        <p class="col-sm-9">{{bookingDetails.cancelDetails.cancelAlertLabel}}</p>
        <div *ngIf="bookingDetails.cancelDetails.showButton == true">
          <button type="button" class="btn btn-danger " (click)="cancelBookingRequest()" color="warn">Cancel booking</button>
        </div>
      </div>
    </div>
    <hr>
    <div>
      <button type="button" class="btn btn-primary submit_btn" data-bs-dismiss="modal"
        (click)="modal.close('Close click')"> Confirm Booking
      </button>
      <button type="button" class="btn btn-outline-primary ms-2 print-btn" (click)="print()" color="warn">
        <span class="material-icons print-icon">print</span> &nbsp;
        <span class="print-text">Print</span>
      </button>
    </div>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>
