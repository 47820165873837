<!-- start page title -->
<app-pagetitle title="Contact Information" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<div class="row mt-3">
    <div class="col-lg-4">
        <div class="card position-absolute z-1 fw-normal ms-3 contact-card-bg" *ngIf="showLightCard">
            <h6 class="card-header bg-light">Data Usage</h6>
            <div class="card-body">
                <p class="card-text">The contact details will be used by HotelCard only internally and are not visible on the website.</p>
            </div>
        </div>
    </div>
</div>
<!-- end page title -->
<div class="row mt-3">
    <div class="col-xl-12">
        <div class="card card-h-100">
            <div class="card-body">
                <div class="">
                    <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="mb-3">
                              <div class="form-check form-check-inline" *ngFor="let option of radioOptions">
                                <input 
                                  class="form-check-input form-input-radio form-input-bg" 
                                  type="radio" 
                                  name="gender" 
                                  [id]="'formRadios-' + option.id"
                                  [(ngModel)]="hotel_contact.gender" 
                                  [value]="option.gender" 
                                  required>
                                <label class="form-check-label" [for]="'formRadios-' + option.id">
                                  {{ option.label | translate }}  <!-- This should work for all languages -->
                                </label>
                              </div>
                              
                            <div *ngIf="contactForm.submitted && !hotel_contact.gender" class="text-danger">
                                Gender is required.
                              </div>
                            </div>
                            
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="firstname">First Name</label>
                              <input type="text" class="form-control form-input-bg" id="firstname" name="first_name" [(ngModel)]="hotel_contact.first_name" required>
                              <div *ngIf="contactForm.submitted && !hotel_contact.first_name" class="text-danger">
                                First name is required.
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="lastname">Last Name</label>
                              <input type="text" class="form-control form-input-bg" id="lastname" name="last_name" [(ngModel)]="hotel_contact.last_name" required>
                              <div *ngIf="contactForm.submitted && !hotel_contact.last_name" class="text-danger">
                                Last name is required.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="role">Role</label>
                              <input type="text" class="form-control form-input-bg" id="role" name="role" [(ngModel)]="hotel_contact.role" required>
                              <div *ngIf="contactForm.submitted && !hotel_contact.role" class="text-danger">
                                Role is required.
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="email">E-mail address</label>
                              <input type="email" class="form-control form-input-bg" id="email" name="email" [(ngModel)]="hotel_contact.email" required>
                              <div *ngIf="contactForm.submitted && !hotel_contact.email" class="text-danger">
                                Email is required.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="booking_email">Hotel Booking email</label>
                              <input type="email" class="form-control form-input-bg" id="booking_email" name="booking_email" [(ngModel)]="hotel.booking_email" required>
                              <div *ngIf="contactForm.submitted && !hotel.booking_email" class="text-danger">
                                Hotel Booking email is required.
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="general_questions">Email for general questions</label>
                              <input type="email" class="form-control form-input-bg" id="general_questions" name="general_questions" [(ngModel)]="hotel.general_email" required>
                              <div *ngIf="contactForm.submitted && !hotel.general_email" class="text-danger">
                                Email for general questions is required.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="phone_hotel">Phone Hotel(incl.international area code)</label>
                              <input type="text" class="form-control form-input-bg" id="phone_hotel" name="phone_hotel" [(ngModel)]="hotel_contact.phone_number" required>
                              <div *ngIf="contactForm.submitted && !hotel_contact.phone_number" class="text-danger">
                                Phone Hotel is required.
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="phone_contact">Phone Contact(incl.international area code)</label>
                              <input type="text" class="form-control form-input-bg" id="phone_contact" name="phone_contact" [(ngModel)]="hotel_contact.phone_number" required>
                              <div *ngIf="contactForm.submitted && !hotel_contact.phone_number" class="text-danger">
                                Phone Contact is required.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-4">
                          <button type="submit" class="btn btn-primary submit_btn w-md">Submit</button>
                        </div>
                      </form>                      
                </div>
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->
