<!-- start page title -->
<app-pagetitle title="Hotel Information" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<div class="row mt-3">
  <div class="col-xl-12">
      <div class="card card-h-100">
          <div class="card-body">
              <form #hotelForm="ngForm" (ngSubmit)="onSubmit(hotelForm)">
                  <div class="row">
                      <div class="col-md-6">
                          <div class="mb-3">
                              <label class="form-label" for="hotelname">Hotel Name</label>
                              <input type="text" class="form-control form-input-bg" id="hotelname" name="hotelname" [(ngModel)]="hotel.name" #hotelname="ngModel" required>
                              <div *ngIf="hotelname.invalid && (hotelname.dirty || hotelname.touched)" class="text-danger">
                                  <div *ngIf="hotelname.errors?.['required']">Hotel Name is required.</div>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="mb-3">
                              <label class="form-label" for="starrating">Star Rating</label>
                              <select class="form-select form-input-bg" id="rating" name="rating" [(ngModel)]="hotel.rating" #rating="ngModel" required>
                                  <option *ngFor="let rate of ratingOptions" [value]="rate.value">{{rate.name}}</option>
                              </select>
                              <div *ngIf="rating.invalid && (rating.dirty || rating.touched)" class="text-danger">
                                  <div *ngIf="rating.errors?.['required']">Star Rating is required.</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-6">
                          <div class="mb-3">
                              <label class="form-label" for="country">Country</label>
                              <select class="form-select form-input-bg" id="country" name="country" [(ngModel)]="hotel.country_id" #country="ngModel" (change)="onCountryChange($event)" required>
                                  <option *ngFor="let country of countries" [value]="country.id">{{ country.name }}</option>
                              </select>
                              <div *ngIf="country.invalid && (country.dirty || country.touched)" class="text-danger">
                                  <div *ngIf="country.errors?.['required']">Country is required.</div>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="mb-3">
                              <label class="form-label" for="currency">Currency</label>
                              <input type="text" class="form-control form-input-bg" id="currency" name="currency" [(ngModel)]="hotel.currency" #currency="ngModel" required>
                              <div *ngIf="currency.invalid && (currency.dirty || currency.touched)" class="text-danger">
                                  <div *ngIf="currency.errors?.['required']">Currency is required.</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-6">
                          <div class="mb-3">
                              <label class="form-label" for="region">Region</label>
                              <select class="form-select form-input-bg" id="region" name="region" [(ngModel)]="hotel.region_id" #region="ngModel">
                                  <option value="">Select</option>
                                  <option *ngFor="let region of regions" [value]="region.id">{{ region.name }}</option>
                              </select>
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="mb-3">
                              <label class="form-label" for="town">Town</label>
                              <input type="text" class="form-control form-input-bg" id="town" name="town" [(ngModel)]="hotel.city" #town="ngModel" required>
                              <div *ngIf="town.invalid && (town.dirty || town.touched)" class="text-danger">
                                  <div *ngIf="town.errors?.['required']">Town is required.</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-4">
                          <div class="mb-3">
                              <label class="form-label" for="street">Street</label>
                              <input type="text" class="form-control form-input-bg" id="street" name="street" [(ngModel)]="hotel.street" #street="ngModel" required>
                              <div *ngIf="street.invalid && (street.dirty || street.touched)" class="text-danger">
                                  <div *ngIf="street.errors?.['required']">Street is required.</div>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-4">
                          <div class="mb-3">
                              <label class="form-label" for="housenumber">House Number</label>
                              <input type="text" class="form-control form-input-bg" id="housenumber" name="housenumber" [(ngModel)]="hotel.number" #housenumber="ngModel" required>
                              <div *ngIf="housenumber.invalid && (housenumber.dirty || housenumber.touched)" class="text-danger">
                                  <div *ngIf="housenumber.errors?.['required']">House Number is required.</div>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-4">
                          <div class="mb-3">
                              <label class="form-label" for="postcode">Postcode</label>
                              <input type="text" class="form-control form-input-bg" id="postcode" name="postcode" [(ngModel)]="hotel.postal_code" #postcode="ngModel" required>
                              <div *ngIf="postcode.invalid && (postcode.dirty || postcode.touched)" class="text-danger">
                                  <div *ngIf="postcode.errors?.['required']">Postcode is required.</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-6">
                          <div class="mb-3">
                              <label class="form-label" for="website">Website</label>
                              <input type="text" class="form-control form-input-bg" id="website" name="website" [(ngModel)]="hotel.website" #website="ngModel">
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="mb-3">
                              <label class="form-label" for="hoteltype">Hotel Type</label>
                              <select class="form-select form-input-bg" id="hoteltype" name="hoteltype" [(ngModel)]="hotel.hotel_type" #hoteltype="ngModel" required>
                                  <option *ngFor="let type of hotelType" [value]="type.value" [selected]="hotel.type == type.value">{{type.value}}>{{type.value}}</option>
                              </select>
                              <div *ngIf="hoteltype.invalid && (hoteltype.dirty || hoteltype.touched)" class="text-danger">
                                  <div *ngIf="hoteltype.errors?.['required']">Hotel Type is required.</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-2">
                          <div class="mb-3">
                              <label class="form-label" for="checkinfrom">Check-in from</label>
                              <input type="time" class="form-control form-input-bg" id="checkinfrom" name="checkinfrom" [(ngModel)]="hotel.checkin_from" #checkinfrom="ngModel">
                          </div>
                      </div>
                      <div class="col-md-2">
                          <div class="mb-3">
                              <label class="form-label" for="checkintill">Check-in until</label>
                              <input type="time" class="form-control form-input-bg" id="checkintill" name="checkintill" [(ngModel)]="hotel.checkin_till" #checkintill="ngModel">
                          </div>
                      </div>
                      <div class="col-md-1">
                          <div class="mb-3">
                              <br/>
                              <label> OR </label>
                          </div>
                      </div>
                      <div class="col-md-2">
                          <br/>
                          <input class="form-check-input" type="checkbox" id="checkin_full_time" name="checkin_full_time" [(ngModel)]="hotel.checkin_full_time">
                          <label class="form-check-label" for="checkin_full_time">
                            &nbsp; Open till late
                        </label>
                    </div>
                    <div class="col-md-2">
                        <div class="mb-3">
                            <label class="form-label" for="checkout">Check-out until</label>
                            <input type="time" class="form-control form-input-bg" id="checkout" name="checkout" [(ngModel)]="hotel.checkout_till" #checkout="ngModel">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <br/>
                            <input class="form-check-input" type="checkbox" id="nophone" name="phone_requests" [(ngModel)]="hotel.phone_requests" #phone_requests="ngModel">
                            <label class="form-check-label">&nbsp; No phone requests</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label" for="availability">Close availability at this time on the day of arrival</label>
                            <input type="time" class="form-control form-input-bg" id="availability" name="availability" [(ngModel)]="hotel.availability_close_time" #availability="ngModel">
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <button type="submit" class="btn btn-primary submit_btn w-md" [disabled]="hotelForm.invalid">Submit</button>
                </div>
            </form>
        </div><!-- end card body -->
    </div><!-- end card -->
</div><!-- end col -->
</div><!-- end row -->
  <div class="card">
    <div class="card-header justify-content-between d-flex align-items-center">
      <h4 class="card-title">Hotel description</h4>
    </div><!-- end card header -->
    <div class="card-body">
        <!-- Nav tabs -->
        <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav nav-tabs nav-justified">
            <li [ngbNavItem]="1">
                <a ngbNavLink>
                  <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                  <span class="d-none d-sm-block">Deutsch</span>
                </a>
                <ng-template ngbNavContent>
                  <textarea class="form-control form-input-bg" placeholder="Leave a comment here" id="floatingTextarea2"
                  style="height: 200px"> {{hotel.description['de']}}</textarea>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink>
                    <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                    <span class="d-none d-sm-block">English</span>
                </a>
                <ng-template ngbNavContent>
                  <textarea class="form-control form-input-bg" placeholder="Leave a comment here" id="floatingTextarea2"
                  style="height: 200px"> {{hotel.description['en']}}</textarea>
                </ng-template>
            </li>
            <li [ngbNavItem]="3">
                <a ngbNavLink>
                    <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                    <span class="d-none d-sm-block">Français</span>
                </a>
                <ng-template ngbNavContent>
                  <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2"
                  style="height: 200px"> {{hotel.description['fr']}}</textarea>
                </ng-template>
            </li>
            <li [ngbNavItem]="4">
              <a ngbNavLink>
                  <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                  <span class="d-none d-sm-block">Italiano</span>
              </a>
              <ng-template ngbNavContent>
                <textarea class="form-control form-input-bg" placeholder="Leave a comment here" id="floatingTextarea2"
                style="height: 200px"> {{hotel.description['it']}}</textarea>
              </ng-template>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content p-3 text-muted">
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div> <!-- end card body -->
</div>

<div class="card">
  <div class="card-header justify-content-between d-flex align-items-center">
      <h4 class="card-title">Hotel secondary description</h4>
  </div><!-- end card header -->
  <div class="card-body">
      <!-- Nav tabs -->
      <ul ngbNav #justifyNav="ngbNav" [activeId]="1" class="nav nav-tabs nav-justified">
          <li [ngbNavItem]="1">
              <a ngbNavLink>
                  <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                  <span class="d-none d-sm-block">Deutsch</span>
              </a>
              <ng-template ngbNavContent>
                <textarea class="form-control form-input-bg" placeholder="Leave a comment here" id="floatingTextarea2"
                style="height: 200px"> {{hotel.secondary_description['de']}}</textarea>
              </ng-template>
          </li>
          <li [ngbNavItem]="2">
              <a ngbNavLink>
                  <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                  <span class="d-none d-sm-block">English</span>
              </a>
              <ng-template ngbNavContent>
                <textarea class="form-control form-input-bg" placeholder="Leave a comment here" id="floatingTextarea2"
                style="height: 200px"> {{hotel.secondary_description['en']}}</textarea>
              </ng-template>
          </li>
          <li [ngbNavItem]="3">
              <a ngbNavLink>
                  <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                  <span class="d-none d-sm-block">Français</span>
              </a>
              <ng-template ngbNavContent>
                <textarea class="form-control form-input-bg" placeholder="Leave a comment here" id="floatingTextarea2"
                style="height: 200px"> {{hotel.secondary_description['fr']}}</textarea>
              </ng-template>
          </li>
          <li [ngbNavItem]="4">
              <a ngbNavLink>
                  <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                  <span class="d-none d-sm-block">Italiano</span>
              </a>
              <ng-template ngbNavContent>
                <textarea class="form-control form-input-bg" placeholder="Leave a comment here" id="floatingTextarea2"
                style="height: 200px"> {{hotel.secondary_description['it']}}</textarea>
              </ng-template>
          </li>
      </ul>
      <!-- Tab panes -->
      <div class="tab-content p-3 text-muted">
          <div [ngbNavOutlet]="justifyNav"></div>
      </div><!-- end tab content -->
  </div><!-- end crad body -->
</div>
