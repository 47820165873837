import { Component, OnInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { Booking } from '../../core/interfaces/booking';
import { Hotel } from '../../core/interfaces/hotel';
import { HotelService } from '../../core/services/hotel.service';
import { BookingResponse } from '../../core/interfaces/booking-response';
import {SortEvent} from "../../core/interfaces/sort-event";
import {BookingListSortableDirective} from '../../core/directives/booking-sorting.directive';
import {setupCustomAngleChart, setupSemiDonutChart} from '../../core/interfaces/booking-chart-data';
import { BookingStatsService } from '../../core/services/booking-stats.service';
import { tap, catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
  providers: [DecimalPipe]
})
export class BookingsComponent implements OnInit, OnDestroy {
  @ViewChildren(BookingListSortableDirective) headers!: QueryList<BookingListSortableDirective>;
  bookings: Booking[] = [];
  selectedHotel: Hotel | null = null;
  private subscriptions: Subscription = new Subscription();
  public hotel$: Observable<Hotel | null>;
  hotelId: any;
  masterSelected: boolean;
  checkedList: any;
  breadCrumbItems!: Array<{}>;
  totalBookings: number = 0;
  isDesc: boolean = false;
  column: string = 'created_at';
  page: number = 1;
  pageSize: number = 25;
  totalRecords: number = 0;
  customAngleChartOptions: any;
  semiDonutChartOptions: any;
  bookingDetails: any;
  loading = false;
  confirm_success = false;
  searchTerm: string = '';
  roomNightPrice: any;
  articlePrice: any;

  constructor(
    private modalService: NgbModal,
    private hotelService: HotelService,
    private bookingStatsService: BookingStatsService
  ) {
    this.hotel$ = this.hotelService.selectedHotel$;
    this.masterSelected = false;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
        this.selectedHotel = hotel;
        if (hotel) {
          this.loadBookings();
          /**
          * Chart Data Fetch Function
          */
          this._fetchData();
        } else {
          this.bookings = [];
        }
      })
    );

    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Bookings', active: true }
    ];
  }

  loadBookings(): void {
    if (this.selectedHotel) {
      const params = {
        page: this.page,
        per_page: this.pageSize,
        sort: this.isDesc ? `-${this.column}` : this.column,
        locale: 'en'
      };
      this.hotelService?.getSelectedHotelBookings(params).subscribe((response: BookingResponse | null) => {
        if (response) {
          this.bookings = response?.data?.map(booking => {
            const checkinDate = new Date(booking?.checkin_date);
            const checkoutDate = new Date(booking?.checkout_date);
            const timeDiff = Math.abs(checkoutDate.getTime() - checkinDate.getTime());
            const nrOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
            const nrOfGuests = booking?.adults + booking?.children + booking?.infants;

            return {
              ...booking,
              nrOfNights,
              nrOfGuests
            };
          });
          this.totalRecords = response?.total;
        }
      });
    }
  }

  sort({ column, direction }: SortEvent): void {
    this.isDesc = direction === 'desc';
    this.column = column;
    this.loadBookings();
  }

  openModal(content: any): void {
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  checkUncheckAll(): void {
    for (let i = 0; i < this.bookings.length; i++) {
      this.bookings[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  isAllSelected(): void {
    this.masterSelected = this.bookings.every(item => item.isSelected);
    this.getCheckedItemList();
  }

  getCheckedItemList(): void {
    this.checkedList = [];
    for (let i = 0; i < this.bookings.length; i++) {
      if (this.bookings[i].isSelected) {
        this.checkedList.push(this.bookings[i]);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onPageChange(page: number): void {
    this.page = page;
    this.loadBookings();
  }
/**
 * Chart Data Fetch
 */
public _fetchData() {
  if (this.selectedHotel) {
    const params = {
      locale: 'en'
    };

    this.hotelService.getSelectedHotelBookings(params).subscribe(
      (response: BookingResponse | null) => {
        if (response) {
          this.hotel$.subscribe({
            next: (hotelData) => {
              const percentages: number[] = [
                hotelData?.totalNumberOfNights.roomsNightsCount.reduced || 0,
                hotelData?.totalNumberOfNights.roomsNightsCount.reduced_30 || 0,
                hotelData?.totalNumberOfNights.roomsNightsCount.reduced_10 || 0
              ];
              const customAngleLabels = ['50% Room Nights', '30% Room Nights', '10% Room Nights'];
              this.customAngleChartOptions = setupCustomAngleChart(percentages, customAngleLabels, this.bookingStatsService);

              const semiDonutSeries = [hotelData?.bookings || 0, hotelData?.cancelledBookings || 0];
              const semiDonutLabels = ['Total Bookings', 'Cancelled Bookings'];
              const semiDonutColors = ['#038edc', '#51d28c', '#f7cc53', '#f34e4e'];
              this.semiDonutChartOptions = setupSemiDonutChart(semiDonutSeries, semiDonutColors, semiDonutLabels);

            },
            error: (err) => {
              console.error('Error fetching hotel data:', err);
            }
          });

        }
      },
      (error) => {
        console.error('Error fetching hotel bookings:', error);
      }
    );
  }
}
/**
 * search the booking records
 */
search() {
  this.hotel$.subscribe({
    next: (hotelData) => {
       this.hotelId = hotelData?.id;
    }
  });
  const filters = {
    page: this.page,
    per_page: this.pageSize,
    sort: this.isDesc ? `-${this.column}` : this.column,
    locale: 'en',
    total: 0,
    loaded_component: true,
    loading_data: true,
    search: this.searchTerm,
    search_context: ''
  };
  return this.bookingStatsService.getHotelAll(this.hotelId, filters).subscribe((response: BookingResponse | null) => {
    if (response) {
      this.bookings = response?.data?.map(booking => {
        const checkinDate = new Date(booking?.checkin_date);
        const checkoutDate = new Date(booking?.checkout_date);
        const timeDiff = Math.abs(checkoutDate.getTime() - checkinDate.getTime());
        const nrOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
        const nrOfGuests = booking?.adults + booking?.children + booking?.infants;

        return {
          ...booking,
          nrOfNights,
          nrOfGuests
        };
      });
      this.totalRecords = response?.total;
    }
  });
}
/**
 * Booking details modal
*/

formatCardNumber(cardNumber: any): string {
  // Check if cardNumber is a string or can be converted to a string
  if (typeof cardNumber !== 'string') {
    cardNumber = String(cardNumber); // Convert to string if it's not
  }

  // Remove any non-digit characters
  const cleaned = cardNumber.replace(/\D/g, '');
  
  // Match the cleaned number with the desired pattern
  const match = cleaned.match(/(\d{3})(\d{3})(\d{3})/);
  
  // If the number doesn't match the pattern, return it as is
  if (!match) return cardNumber;
  
  // Format as 'xxx-xxx-xxx'
  return `${match[1]}-${match[2]}-${match[3]}`;
}


openbookingDetailsModal(showBookingDetailsModal:any, booking: any, confirm_success = false): void {
  if (confirm_success) {
    booking.confirm_success = true;
  }
  this.bookingStatsService.getBooking(booking.id).subscribe({
    next: (response: any) => {
      this.roomNightPrice = response.data.bookingPrices.roomNightPrice;
      this.articlePrice = response.data.bookingPrices.articlePrice;
      // Format the card number
      booking.card_number = this.formatCardNumber(booking.card_number);
      if (response.data.salutation == 'f' ) {
        this.bookingDetails.salutation = 'Ms.';
      } else {
        this.bookingDetails.salutation = 'Mr.';
      }
    }
  });
  this.bookingDetails = booking;
  this.modalService.open(showBookingDetailsModal);
}
confirmBooking(bookingData: { hotel_id: number, booking_id: number, key: string }) {
  this.loading = true; // Show loading spinner
  this.confirm_success = false; // Reset success flag
  this.bookingStatsService.confirmBooking(bookingData).subscribe({
    next: (response: any) => {
      // Handle the success response here
      this.loading = false;
      this.confirm_success = true;
      // Optionally update booking status locally
      this.bookingDetails.status = 'confirmed';
      // Optionally close the modal or give user feedback
      this.modalService.dismissAll();
    },
    error: (error: any) => {
      // Handle error response here
      this.loading = false;
      this.confirm_success = false;
      console.error('Error confirming booking:', error);
    }
  });
}
print() {
  window.print();
}
cancelBookingRequest() {
  this.bookingStatsService.cancelBooking(this.bookingDetails).subscribe({
    next: (response:any) => {
      this.bookingDetails.status = 'cancelled';
      // Optionally close the modal or give user feedback
      this.modalService.dismissAll();
    },
    error: (error: any) => {
      console.error(error.data);
    }
  });
}
}

