import { Component, OnInit } from '@angular/core';
import { Hotel } from '../../core/interfaces/hotel';
import { HotelService } from '../../core/services/hotel.service';
import { CountryService } from '../../core/services/country.service';
import { Country } from '../../core/interfaces/country';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-hotel-information',
  templateUrl: './hotel-information.component.html',
  styleUrls: ['./hotel-information.component.scss']
})
export class HotelInformationComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  public hotel: any = {};
  public ratingOptions: any = {};
  public country: any = {};
  public hotelType: any = {};
  countries:Country[]=[];
  regions:Country[]=[];

  constructor(private hotelService: HotelService,
    private countryService: CountryService
  )
  {
    this.ratingOptions = [
      {value: 1, name: 1},
      {value: 2, name: 2},
      {value: 3, name: 3},
      {value: 4, name: 4},
      {value: 5, name: 5},
      {value: 'no_rating', name: 'No Rating'},
      {value: 6, name: 'Swiss Lodge'}
    ];
    this.hotelType = [
      {id:152, value:'Full year'},
      {id:153, value:'Seasonal'}
    ];
  }

  ngOnInit(): void {
    // Initialize component
    this.subscribeToSelectedHotel();
    this.loadCountries();
    this.initializeBreadcrumbs();

  }

  loadCountries(): void {
    this.countryService.getAllCountries().subscribe(
      (data: Country[]) => {
        this.countries = data;
        // this.setDefaultCountry(); // Set default country after loading
        this.fetchRegionsByHotelCountry();
      },
      (error) => {
        console.error('Error fetching countries:', error);
      }
    );
  }

  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Hotel information', active: true }
    ];
  }

  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
        // this.setInitialCountryAndRegions(); // Set default country and regions based on hotel's data
        this.fetchRegionsByHotelCountry();
      }
    });
  }

  setDefaultCountry(): void {
    const defaultCountryIndex = this.getDefaultCountryIndex();
    if (defaultCountryIndex !== undefined) {
      this.fetchRegionsByCountry(defaultCountryIndex);
    }
  }

  setInitialCountryAndRegions(): void {
    const defaultCountryIndex = this.getDefaultCountryIndex();
    if (defaultCountryIndex !== undefined) {
      this.fetchRegionsByCountry(defaultCountryIndex);
    }
  }

  fetchRegionsByHotelCountry(): void {
    if (this.hotel.country_id) {
      const countryIndex = this.countries.findIndex(country => country.id === this.hotel.country_id);
      if (countryIndex !== -1) {
        this.fetchRegionsByCountry(countryIndex);
      }
    }
  }

  fetchRegionsByCountry(countryIndex: number): void {
    const selectedCountry = this.countries[countryIndex];
    this.countryService.getRegions(selectedCountry.id).subscribe(
      (regions: any[]) => {
        this.regions = regions;
      },
      (error) => {
        console.error(`Error fetching regions for country ${selectedCountry.name}:`, error);
      }
    );
  }
    setPhoneRequests(event: any): void{
      if (event.checked == true) {
        this.hotel.phone_requests = 1;
      } else {
        this.hotel.phone_requests = 0;
      }
    }
    onSubmit(form: NgForm): void {
      if (form.valid) {
        console.log('Form Data:', form.value);
        // Handle the form submission logic here
      } else {
        console.log('Form is invalid');
      }
    }

  getDefaultCountryIndex(): number | undefined {
    return this.countries.findIndex(country =>
      ['Switzerland', 'France'].includes(country.name)
    );
  }
  onCountryChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const countryIndex = this.countries.findIndex(country => country.id === +selectElement.value);
    if (countryIndex !== undefined) {
      this.fetchRegionsByCountry(countryIndex);
    }
  }
}
