import { Component, OnInit } from '@angular/core';
import { HotelService } from '../../core/services/hotel.service';
import { Hotel } from '../../core/interfaces/hotel';
import { ContactInformationService } from '../../core/services/contact-information.service';
import { NgForm } from '@angular/forms';
import { CountryService } from '../../core/services/country.service';
import { Country } from '../../core/interfaces/country';
import { CONTACT } from './contact';
import { ContactInfo } from '../../core/interfaces/contact-info';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrl: './contact-information.component.scss'
})
export class ContactInformationComponent implements OnInit {
// bread crumb items
breadCrumbItems!: Array<{}>;
public hotel: any = {};
public hotel_contact: any = {};
radioSelection: string = '';
radioOptions: any = {};
showLightCard: boolean = false;
countries: any;
contactInfo: ContactInfo[] = [];
languages: { id: number, name: string }[] = [
  { id: 215, name: 'German' },
  { id: 83, name: 'German' },
  { id: 110, name: 'Italian' },
  { id: 15, name: 'Austrian' },
  { id: 75, name: 'French' }
];

constructor(private hotelService: HotelService,private contactInformationService: ContactInformationService,private countryService: CountryService,public translate: TranslateService)
{
  // this.translate.setDefaultLang('en');
  // this.translate.use('en');
  this.contactInfo = CONTACT;
  // this.radioOptions = [
  //   { id: 1, gender: 'male', label: this.contactInfo },
  //   { id: 2, gender: 'female', label: this.contactInfo },
  // ];
  this.radioOptions = this.contactInfo.map((contact) => {
    console.log('Contact:', contact.label); // Log the current contact being processed
    return {
      id: contact.id,
      gender: contact.label === 'CONTACTINFO.MALE' ? 'male' : 'female',
      label: contact.label // This should map to the translation keys
    };
  });
  
}
ngOnInit(): void {
  this.subscribeToSelectedHotel();
  this.fetchByCountries();
  this.contactInformationService.getShowLightCard().subscribe(show => {
    this.showLightCard = show;
  });
  this.initiliazeBreadCrumbs();
  }
  initiliazeBreadCrumbs(){
   /**
   * BreadCrumb
   */
    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Contact information', active: true }
    ];
  }
  fetchByCountries(){
    this.countryService.getAllCountries().subscribe(
      (data: Country[]) => {
        this.countries = data;
        this.fetchRegionsByHotelCountry();
      },
      (error) => {
        console.error('Error fetching countries:', error);
      }
    );
  }
  fetchRegionsByHotelCountry(): void {
    if (this.hotel.country_id) {
      const countryIndex = this.countries.findIndex((country:any) => country.id === this.hotel.country_id);
    }
  }
  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
        console.log(this.hotel);
        this.hotel_contact = this.hotel.hotel_contact;
        this.fetchRegionsByHotelCountry();
      }
    });
  }
  onSubmit(form: NgForm): void {
    if (form.valid) {
      console.log('Form Submitted!', this.hotel_contact);
      // Handle form submission logic here
    } else {
      console.log('Form is not valid');
      // You can also set some error state or display messages
    }
  }
}
